/* istanbul ignore file */

import { formatDate } from './businessPartnerMasterDetailTables';

export const masterDetailsTablesProps = {
  branch_customer_kyc: {
    title: 'Customer KYC',
    ajaxURL:
      '/api/kyc-documents/?format=json&partner_vetting=True&partner_type__customer=True&fields=id,name,partner_type_name,branch,',
    addURLs: [
      {
        name: 'Create Customer KYC',
        url: '/settings/branch_kyc_documents/customer/new',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Document Name', field: 'name' },
      { title: 'Type', field: 'partner_type_name' },
      {
        title: 'Edit',
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.branch === undefined || rowData.branch === null) {
            return '';
          } else {
            return (
              '<a href="/settings/branch_customer_kyc_documents/update/' +
              rowData.id +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.branch === undefined || rowData.branch === null) {
            return '';
          } else {
            return (
              '<a href="/settings/branch_kyc_documents/delete/' +
              rowData.id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  branch_supplier_kyc: {
    title: 'Supplier KYC',
    ajaxURL:
      '/api/kyc-documents/?format=json&partner_vetting=True&partner_type__customer=False&fields=id,name,partner_type_name,branch,',
    addURLs: [
      {
        name: 'Create Supplier KYC',
        url: '/settings/branch_kyc_documents/supplier/new',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Document Name', field: 'name' },
      { title: 'Type', field: 'partner_type_name' },
      {
        title: 'Edit',
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.branch === undefined || rowData.branch === null) {
            return '';
          } else {
            return (
              '<a href="/settings/branch_supplier_kyc_documents/update/' +
              rowData.id +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.branch === undefined || rowData.branch === null) {
            return '';
          } else {
            return (
              '<a href="/settings/branch_kyc_documents/delete/' +
              rowData.id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  branch_staff_kyc: {
    title: 'Staff KYC Documents',
    ajaxURL:
      '/api/kyc-documents/?format=json&staff_vetting=True&fields=id,name,staff_type_name,branch,',
    addURLs: [
      {
        name: 'Create Staff KYC',
        url: '/settings/branch_kyc_documents/staff/new',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Document Name', field: 'name' },
      { title: 'Type', field: 'staff_type_name' },
      {
        title: 'Edit',
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.branch === undefined || rowData.branch === null) {
            return '';
          } else {
            return (
              '<a href="/settings/branch_staff_kyc_documents/update/' +
              rowData.id +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.branch === undefined || rowData.branch === null) {
            return '';
          } else {
            return (
              '<a href="/settings/branch_kyc_documents/delete/' +
              rowData.id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  collection_lines: {
    title: 'Collection Line Items',
    ajaxURL:
      '/api/collection-line-item/?format=json&fields=id,class_of_service_name,quantity,metadata,subtotal,tax_amount,unit_price,total_price,currency,discount,collection_status',
    columns: [
      { title: 'Class Of Service', field: 'class_of_service_name' },
      { title: 'Quantity', field: 'quantity' },
      {
        title: 'Weight (Kg)',
        field: 'metadata',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.metadata.weight;
        },
      },
      {
        title: 'Subtotal',
        field: 'subtotal',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let subtotal = (rowData.total_price - rowData.tax_amount).toFixed(2);
          return `${subtotal} ${rowData.currency}`;
        },
      },
      {
        title: 'Tax Amount',
        field: 'tax_amount',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let taxAmount = rowData.tax_amount;
          return `${taxAmount} ${rowData.currency}`;
        },
      },
      {
        title: 'Unit Price',
        field: 'unit_price',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let unitPrice = rowData.unit_price;
          return `${unitPrice} ${rowData.currency}`;
        },
      },
      {
        title: 'Total Amount',
        field: 'total_price',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let TotalPrice = rowData.total_price;
          return `${TotalPrice} ${rowData.currency}`;
        },
      },
      {
        title: 'Discount',
        field: 'discount',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let discount = rowData.discount;
          return `${discount} ${rowData.currency}`;
        },
      },
      {
        title: 'Price Logs',
        formatter: function (cell) {
          return `<a href="/deliveries/collection_line_price_log/${
            cell.getRow().getData().id
          }/">Price Log</a>`;
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let collectionStatus = cell.getRow().getData().collection_status;
          let isDraft =
            collectionStatus === 'DRAFT' || collectionStatus === 'READY';
          let href = isDraft
            ? `/deliveries/edit_collection_line/${cell.getRow().getData().id}/`
            : '#';
          return isDraft
            ? `<a href="${href}"><i class="fas fa-edit"></i></a>`
            : `<a onclick="return false;" href="${href}"><i class="fas fa-edit text-muted"></i></a>`;
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          let collectionStatus = cell.getRow().getData().collection_status;
          let isDraft = collectionStatus === 'DRAFT';
          let href = isDraft
            ? `/deliveries/delete_collection_line/${
                cell.getRow().getData().id
              }/`
            : '#';
          return isDraft
            ? `<a href="${href}"><i class="fa fa-trash text-danger"></i></a>`
            : `<a onclick="return false;" href="${href}"><i class="fa fa-trash text-muted"></i></a>`;
        },
      },
    ],
  },
  collection_lines_price_log: {
    title: 'Collection Line Item Price Log',
    ajaxURL:
      '/api/collection-line-item-price-logs/?format=json&fields=id,updated,updated_by,rate_card,rating_table,quantity,tax_name,tax_amount,total_price,currency',
    columns: [
      {
        title: 'Created By',
        field: 'updated_by',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let userName = rowData.user_name ? rowData.user_name : null;
          return `${userName}`;
        },
      },
      {
        title: 'Date',
        field: 'updated',
      },
      {
        title: 'Rate Card',
        field: 'rate_card',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.rate_card !== null ? rowData.rate_card.name : '-';
        },
      },
      {
        title: 'Rating Table',
        field: 'rating_table',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.rating_table !== null
            ? rowData.rating_table.name
            : '-';
        },
      },
      { title: 'Quantity', field: 'quantity' },

      {
        title: 'Tax',
        field: 'tax_name',
      },
      {
        title: 'Tax Amount',
        field: 'tax_amount',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let taxAmount = rowData.tax_amount;
          return `${taxAmount} ${rowData.currency}`;
        },
      },

      {
        title: 'Total Amount',
        field: 'total_price',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let TotalPrice = rowData.total_price;
          return `${TotalPrice} ${rowData.currency}`;
        },
      },
    ],
  },
  unit_of_measure: {
    title: 'Unit Of Measure',
    ajaxURL:
      '/api/unit-of-measure/?format=json&fields=id,name,category_type,uom_type,factor,system_generated',
    addURLs: [
      {
        name: 'Create Unit Of Measure',
        url: '/products/create_unit_of_measure',
      },
    ],
    exportURL: '/products/export_unit_of_measure',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Category', field: 'category_type' },
      { title: 'Type', field: 'uom_type' },
      { title: 'Factor', field: 'factor' },
      {
        title: 'Edit',
        formatter: function (cell) {
          if (cell.getRow().getData().system_generated === true) {
            return '';
          } else {
            return (
              '<a href="/products/update_unit_of_measure/' +
              cell.getRow().getData().id +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        formatter: function (cell) {
          if (cell.getRow().getData().system_generated === true) {
            return '';
          } else {
            return (
              '<a href="/products/delete_unit_of_measure/' +
              cell.getRow().getData().id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  skus: {
    title: 'Stock Keeping Units',
    ajaxURL:
      '/api/skus/?format=json&fields=id,name,description,can_be_sold,selling_price,can_be_bought,purchasing_price,system_generated',
    exportURL: '/',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Description', field: 'description' },
      {
        title: 'Can Be Sold',
        field: 'can_be_sold',
        formatter: function (cell) {
          if (cell.getValue() === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Sales Price',
        formatter: function (cell) {
          if (cell.getRow().getData().can_be_sold === true) {
            return 'KES ' + cell.getRow().getData().selling_price;
          } else {
            return '-';
          }
        },
      },
      {
        title: 'Can Be Purchased',
        field: 'can_be_bought',
        formatter: function (cell) {
          if (cell.getValue() === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Purchase Price',
        formatter: function (cell) {
          if (cell.getRow().getData().can_be_bought === true) {
            return 'KES ' + cell.getRow().getData().purchasing_price;
          } else {
            return '-';
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          if (cell.getRow().getData().system_generated === true) {
            return '';
          } else {
            return (
              '<a href="/products/update_sku/' +
              cell.getRow().getData().id +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        formatter: function (cell) {
          if (cell.getRow().getData().system_generated === true) {
            return '';
          } else {
            return (
              '<a href="/products/delete_sku/' +
              cell.getRow().getData().id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  branch_accounts: {
    title: 'Accounts',
    ajaxURL:
      '/api/branch-accounts/?format=json&fields=id,name,number,currency_balance,heading,heading_type,url',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Number', field: 'number' },
      { title: 'Balance', field: 'currency_balance' },
      {
        title: 'Type',
        field: 'heading',
        formatter: function (cell) {
          return cell.getRow().getData().heading_type;
        },
      },
      {
        title: 'Action',
        formatter: function (cell) {
          return (
            '<a class="btn btn-sm btn-success" style="font-size:12px;" href="' +
            cell.getRow().getData().url +
            '">View</a>'
          );
        },
      },
    ],
  },
  branch_contact_persons: {
    title: 'Contact Persons',
    ajaxURL:
      '/api/branch-contact-persons/?format=json&fields=id,contact_details,primary',
    columns: [
      {
        title: 'Name',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.name
            ? rowData.contact_details.name
            : '-';
        },
      },
      {
        title: 'Phone',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.phone
            ? rowData.contact_details.phone
            : '-';
        },
      },
      {
        title: 'Secondary Phone',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.secondary_phone
            ? rowData.contact_details.secondary_phone
            : '-';
        },
      },
      {
        title: 'Email',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.email
            ? rowData.contact_details.email
            : '-';
        },
      },
      {
        title: 'Secondary Email',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.secondary_email
            ? rowData.contact_details.secondary_email
            : '-';
        },
      },
      {
        title: 'Job Title',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.designation
            ? rowData.contact_details.designation
            : '-';
        },
      },
      {
        title: 'Primary Contact',
        sort: false,
        formatter: function (cell) {
          if (cell.getRow().getData().primary === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Edit Contact Person',
        sort: false,
        formatter: function (cell) {
          return `<a href="/branches/edit_branch_contact_person/${
            cell.getRow().getData().id
          }/" class=""><i class="fas fa-edit"></i>
            </a>`;
        },
      },
      {
        title: 'Delete Contact Person',
        sort: false,
        formatter: function (cell) {
          return `<a href="/branches/delete_branch_contact_person/${
            cell.getRow().getData().id
          }/" class=""><i class="fa fa-trash text-danger" aria-hidden="true"></i>
            </a>`;
        },
      },
    ],
  },
  branch_contact_addresses: {
    title: 'Contact Persons',
    ajaxURL:
      '/api/branch-contact-address/?format=json&fields=id,contact_details,primary',
    columns: [
      {
        title: 'Contact',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.value;
        },
      },
      {
        title: 'Type',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.contact_type;
        },
      },
      {
        title: 'Kind',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.kind;
        },
      },
      {
        title: 'Primary Contact',
        sort: false,
        formatter: function (cell) {
          if (cell.getRow().getData().primary === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Edit Contact Address',
        sort: false,
        formatter: function (cell) {
          return `<a href="/branches/edit_branch_contact_address/${
            cell.getRow().getData().id
          }/" class="">
            <i class="fas fa-edit"></i></a>`;
        },
      },
      {
        title: 'Delete Contact Address',
        sort: false,
        formatter: function (cell) {
          return `<a href="/branches/delete_branch_contact_address/${
            cell.getRow().getData().id
          }/" class="">
            <i class="fa fa-trash text-danger" aria-hidden="true"></i></a>`;
        },
      },
    ],
  },
  branch_users: {
    title: 'Users',
    ajaxURL: '/api/users/?format=json&fields=id,name,phone,email',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Number', field: 'phone' },
      { title: 'Email', field: 'email' },
      {
        title: 'View User Details',
        formatter: function (cell) {
          return `<a class="btn btn-sm btn-success" style="font-size:12px;" href="/users/user/detail/${
            cell.getRow().getData().id
          }">View</a>`;
        },
      },
      {
        title: 'Unassign User From Branch',
        formatter: function (cell) {
          const branch_id = document.querySelector('#branch_id').innerText;
          const user_id = cell.getRow().getData().id;
          const baseUrl = window.location.origin; // Get the base URL (e.g., http://127.0.0.1:8000)
          const url = `${baseUrl}/branches/unassign/user/${user_id}/branch/${branch_id}/`;

          return `<a href="${url}">
            <i class="fa fa-trash text-danger" aria-hidden="true"></i>
            </a>`;
        },
      },
    ],
  },
  branch_stores: {
    title: 'Stores',
    ajaxURL:
      '/api/store-branch-allocation/?format=json&fields=id,store_details',
    columns: [
      {
        title: 'Name',
        field: 'store_details',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.store_details.name;
        },
      },
    ],
  },
  branch_weekly_schedules: {
    title: 'Branch Weekly Schedule',
    ajaxURL:
      '/api/slots/?format=json&fields=id,title,start,end,has_cancelled_occurrences',
    columns: [
      {
        title: 'Day Of The Week',
        field: 'title',
      },
      {
        title: 'Opening Hours',
        field: 'start',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          if (rowData.has_cancelled_occurrences) {
            return 'closed';
          } else {
            return rowData.start;
          }
        },
      },
      {
        title: 'Closing Hours',
        field: 'end',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          if (rowData.has_cancelled_occurrences) {
            return '-';
          } else {
            return rowData.end;
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a href="/schedules/update_branch_schedule/${rowData.id}/"><i class="fas fa-edit"></i></a>`;
        },
      },
    ],
  },
  date_specific_schedules: {
    title: 'Branch Weekly Schedule',
    ajaxURL:
      '/api/occurrences/?format=json&fields=id,start_date,start,end,title,cancelled',
    columns: [
      {
        title: 'Date',
        field: 'start',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.start_date;
        },
      },
      {
        title: 'Day Of The Week',
        field: 'title',
      },
      {
        title: 'Opening Hours',
        field: 'start',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          if (rowData.cancelled) {
            return 'closed';
          } else {
            return rowData.start;
          }
        },
      },
      {
        title: 'Closing Hours',
        field: 'end',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          if (rowData.cancelled) {
            return '-';
          } else {
            return rowData.end;
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a href="/schedules/occurrence_update/${rowData.id}/"><i class="fas fa-edit"></i></a>`;
        },
      },
    ],
  },
  zone_partners: {
    title: 'Partners',
    ajaxURL:
      '/api/territory_partner_locations/?format=json&fields=id,partner_name,location_address,territory_name,partner_url,territory_url',
    columns: [
      {
        title: 'Name',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.partner_name;
        },
      },
      {
        title: 'Address',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.location_address;
        },
      },
      {
        title: 'Territory',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.territory_name;
        },
      },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            href="${rowData.partner_url}">View Partner</a>
            <a
            class="btn btn-sm btn-secondary"
            style="font-size:12px;"
            href="${rowData.territory_url}">View Territory</a>`;
        },
      },
    ],
  },
  zone_users: {
    title: 'Staff',
    ajaxURL:
      '/api/territory_users/?format=json&fields=id,user_name,user_email,user_url',
    columns: [
      {
        title: 'Name',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.user_name;
        },
      },
      {
        title: 'Email',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.user_email;
        },
      },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            href="${rowData.user_url}">View Staff</a>
            <a
            class="ml-3 btn btn-sm btn-danger"
            style="font-size:12px;"
            href="${rowData.url}">
            <i class="fa fa-trash" aria-hidden="true"></i></a>
            `;
        },
      },
    ],
  },
  branch_territories: {
    title: 'Partners',
    ajaxURL:
      '/api/branch_territories/?format=json&fields=id,partner_name,location_address,territory_name,partner_url,territory_url',
    columns: [
      {
        title: 'Name',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.territory_name;
        },
      },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            href="${rowData.territory_url}">View Territory</a>`;
        },
      },
    ],
  },
  zone_routes: {
    title: 'Partners',
    ajaxURL: '/api/routes/?format=json&fields=id,route_name,description,url',
    columns: [
      {
        title: 'Name',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.route_name;
        },
      },
      {
        title: 'Description',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.description;
        },
      },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            href="${rowData.url}">View Route</a>`;
        },
      },
    ],
  },
  user_branches: {
    title: 'Branches',
    ajaxURL:
      '/api/organisation-branches/?format=json&fields=id,name,email_address,phone_number,url',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Email', field: 'email_address' },
      { title: 'Phone', field: 'phone_number' },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            href="${rowData.url}">View</a>`;
        },
      },
    ],
  },
  user_roles: {
    title: 'Roles',
    ajaxURL: '/api/roles/?format=json&fields=id,name,description',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Description', field: 'description' },
    ],
  },
  user_zones: {
    title: 'Roles',
    ajaxURL:
      '/api/territory_users/?format=json&fields=id,territory_name,territory_url',
    columns: [
      { title: 'Name', field: 'territory_name' },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            href="${rowData.territory_url}">View</a>`;
        },
      },
    ],
  },
  user_staff: {
    title: 'Staff',
    ajaxURL:
      '/api/user_staff/?format=json&fields=id,user_name,user_email,user_url',
    columns: [
      { title: 'Name', field: 'user_name' },
      { title: 'Email', field: 'user_email' },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            href="${rowData.user_url}">View</a>`;
        },
      },
    ],
  },
  staff_user: {
    title: 'Staff',
    ajaxURL:
      '/api/user_staff/?format=json&fields=id,manager_name,manager_email,manager_url',
    columns: [
      { title: 'Name', field: 'manager_name' },
      { title: 'Email', field: 'manager_email' },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            href="${rowData.manager_url}">View</a>`;
        },
      },
    ],
  },
  user_document_attachment: {
    title: 'User Document Attachment',
    ajaxURL:
      '/api/staff-document-attachment/?format=json&fields=id,title,description,content_type',
    columns: [
      { title: 'Document Name', field: 'title' },
      {
        title: 'Description',
        field: 'description',
        formatter: function (cell) {
          return cell.getValue() ? cell.getValue() : '-';
        },
      },
      {
        title: 'Type',
        field: 'content_type',
        formatter: function (cell) {
          return cell.getValue() ? cell.getValue() : '-';
        },
      },
      {
        title: 'Download',
        formatter: function (cell) {
          return `<a href="/staff/download_staff_attachment/${
            cell.getRow().getData().id
          }"><i class="fa fa-download" aria-hidden="true"></i></a>`;
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          return `<a href="/staff/update_staff_document/${
            cell.getRow().getData().id
          }"><i class="fas fa-edit"></i></a>`;
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          return `<a href="/staff/delete_staff_document/${
            cell.getRow().getData().id
          }"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>`;
        },
      },
    ],
  },
  user_transition_history: {
    title: 'User Transition History',
    ajaxURL:
      '/api/user-transition-logs/?format=json&fields=status_from,status_to,user_name,note,created',
    columns: [
      {
        title: 'Create At',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().created;
          let formattedDate = formatDate(rowData);
          return `<span class="d-block">${formattedDate}</span>`;
        },
      },
      {
        title: 'Status From',
        field: 'status_from',
        formatter: function (cell) {
          return (
            cell.getValue().charAt(0).toUpperCase() +
            cell.getValue().slice(1).toLowerCase()
          );
        },
      },
      {
        title: 'Status To',
        field: 'status_to',
        formatter: function (cell) {
          return (
            cell.getValue().charAt(0).toUpperCase() +
            cell.getValue().slice(1).toLowerCase()
          );
        },
      },
      {
        title: 'Created By',
        field: 'user_name',
        formatter: function (cell) {
          return cell.getValue() ? cell.getValue() : '-';
        },
      },
      {
        title: 'Notes',
        field: 'note',
      },
    ],
  },
};
